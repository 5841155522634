<template>
    <v-fade-transition>
        <div class="col-12 pa-0">
            <v-sheet class="dense-inputs col-9 col-xl-6 pb-0">
                <div class="d-flex flex-row align-end">
                    <div class="d-flex flex-column">
                        <span class="font-sm">{{ $t('message.warehouse') }}</span>
                        <v-select
                            :items="filteredOffices"
                            class="filter-width-100 bg-lightblue"
                            dense
                            hide-details="auto"
                            item-value="Office.code"
                            item-text="Office.code"
                            solo
                            v-model="filterValues.warehouse"
                            @change="fetchReport()"
                        />
                    </div>
                    <div class="d-flex flex-column ml-3">
                        <span class="font-sm">{{ $t('message.week') }}</span>
                        <v-select
                            :items="calendarWeeks"
                            class="filter-width-100 bg-lightblue"
                            dense
                            hide-details="auto"
                            item-value="Calendarweek.id"
                            item-text="Calendarweek.title"
                            menu-props="auto"
                            ref="weekSelector"
                            solo
                            v-model="filterValues.week"
                            @change="fetchReport()"
                        />
                    </div>
                    <v-btn class="ml-3" @click="fetchReport()" :loading="loading.weeklySales">{{ $t('message.fetch') }}</v-btn>
                    <v-spacer/>
                    <ExportTableJson
                        :export-data="{
                            stocks: stocks,
                            sales: sales,
                            totalSales: totalSales,
                            totalStocks: totalStocks,
                            week: 'WK' + filterValues.week
                        }"
                        :export-fields="[]"
                        :export-source="'weekly-sales'"
                    />
                </div>
            </v-sheet>
            <v-overlay
                :value="loading.weeklySales"
                absolute
                opacity="0.15"
            >
                <v-row>
                    <v-col class="text-center">
                        <v-progress-circular
                            color="primary"
                            indeterminate
                            size="40"
                            width="6"
                        />
                    </v-col>
                </v-row>
            </v-overlay>
            <div class="no-gutters col-9 col-xl-6">
                <v-simple-table dense class="appic-table-light" :key="salesTableKey">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th  @click="openSalesDrillDown()" colspan="12" class="text-center light-green lighten-1" style="cursor: pointer; font-size: 1.0em !important;">
                                    {{ filterValues.warehouse + ' ' + $t('message.sales') + ' WK ' + filterValues.week + ' (' + salesWeekRange + ')' }}
                                </th>
                            </tr>
                            <tr>
                                <th rowspan="2" class="text-start light-green lighten-2 pa-1" style="width: 100px !important; vertical-align: bottom !important; padding-bottom: 7px !important;">{{ $t('message.product') }}</th>
                                <th colspan="4" class="text-start light-green lighten-3 pa-1" style="text-align: center !important;">{{ $t('message.freshStock') }}</th>
                                <th colspan="4" class="text-start light-green lighten-4 pa-1" style="text-align: center !important;">{{ $t('message.oldStock') }}</th>
                                <th colspan="3" class="text-start light-blue lighten-3 pa-1" style="width: 100px !important; text-align: center !important;">{{ $t('message.total') }}</th>
                            </tr>
                            <tr>
                                <th class="text-end light-green lighten-3 pa-1" style="width: 100px !important;">{{ $t('message.quantity') }}</th>
                                <th class="text-end light-green lighten-3 pa-1" style="width: 100px !important;">{{ $t('message.value') + ' USD' }}</th>
                                <th class="text-end light-green lighten-3 pa-1" style="width: 75px !important;">{{ $t('message.margin') + ' USD' }}</th>
                                <th class="text-end light-green lighten-3 pa-1" style="width: 75px !important;">{{ $t('message.margin') + ' %' }}</th>
                                <th class="text-end light-green lighten-4 pa-1" style="width: 75px !important;">{{ $t('message.quantity') }}</th>
                                <th class="text-end light-green lighten-4 pa-1" style="width: 75px !important;">{{ $t('message.value') + ' USD' }}</th>
                                <th class="text-end light-green lighten-4 pa-1" style="width: 75px !important;">{{ $t('message.margin') + ' USD' }}</th>
                                <th class="text-end light-green lighten-4 pa-1" style="width: 75px !important;">{{ $t('message.margin') + ' %' }}</th>
                                <th class="text-end light-blue lighten-3 pa-1" style="width: 50px !important;">{{ $t('message.value') + ' USD' }}</th>
                                <th class="text-end light-blue lighten-3 pa-1">{{ $t('message.margin') + ' USD' }}</th>
                                <th class="text-end light-blue lighten-3 pa-1">{{ $t('message.margin') + ' %' }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="sale in sales">
                                <td class="text-start font-weight-bold">{{ sale.Stock.product }}</td>
                                <td class="text-start text-end">
                                    <span v-if="sale.Stock.freshVolume > 0">{{ numberFormat(sale.Stock.freshVolume,'0,0.00') }}</span>
                                    <span v-else>{{ numberFormat(0,'0,0.00') }}</span>
                                </td>
                                <td class="text-start text-end">
                                    <span v-if="sale.Stock.freshValue > 0">{{ numberFormat(sale.Stock.freshValue,'0,0') }}</span>
                                    <span v-else>{{ numberFormat(0,'0,0') }}</span>
                                </td>
                                <td class="text-start text-end">
                                    <span v-if="sale.Stock.freshMargin > 0">{{ numberFormat(sale.Stock.freshMargin,'0,0') }}</span>
                                    <span v-else>{{ numberFormat(0,'0,0') }}</span>
                                </td>
                                <td class="text-start text-end">
                                    <span v-if="sale.Stock.freshMarginPct > 0">{{ numberFormat(sale.Stock.freshMarginPct,'0.0') }}</span>
                                    <span v-else>{{ numberFormat(0,'0,0') }}</span>
                                </td>
                                <td class="text-start text-end">
                                    <span v-if="sale.Stock.oldVolume > 0">{{ numberFormat(sale.Stock.oldVolume,'0,0.00') }}</span>
                                    <span v-else>{{ numberFormat(0,'0,00') }}</span>
                                </td>
                                <td class="text-start text-end">
                                    <span v-if="sale.Stock.oldValue > 0">{{ numberFormat(sale.Stock.oldValue,'0,0') }}</span>
                                    <span v-else>{{ numberFormat(0,'0,0') }}</span>
                                </td>
                                <td class="text-start text-end">
                                    <span v-if="sale.Stock.oldMargin > 0">{{ numberFormat(sale.Stock.oldMargin,'0,0') }}</span>
                                    <span v-else>{{ numberFormat(0,'0,0') }}</span>
                                </td>
                                <td class="text-start text-end">
                                    <span v-if="sale.Stock.oldMarginPct > 0">{{ numberFormat(sale.Stock.oldMarginPct,'0.0') }}</span>
                                    <span v-else>{{ numberFormat(0,'0.0') }}</span>
                                </td>
                                <td class="text-start text-end">
                                    <span v-if="sale.Stock.totalValue > 0">{{ numberFormat(sale.Stock.totalValue,'0,0') }}</span>
                                    <span v-else>{{ numberFormat(0,'0,0') }}</span>
                                </td>
                                <td class="text-start text-end">
                                    <span v-if="sale.Stock.totalMargin > 0">{{ numberFormat(sale.Stock.totalMargin,'0,0') }}</span>
                                    <span v-else>{{ numberFormat(0,'0,0') }}</span>
                                </td>
                                <td class="text-start text-end">
                                    <span v-if="sale.Stock.totalMarginPct > 0">{{ numberFormat(sale.Stock.totalMarginPct,'0.0') }}</span>
                                    <span v-else>{{ numberFormat(0,'0.0') }}</span>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td class="grey lighten-2 text-start font-weight-bold pa-1">{{ $t('message.totals') }}</td>
                                <td class="grey lighten-2 text-start text-end font-weight-bold">{{ numberFormat(totalSales.fresh.volume,'0,0.00') }}</td>
                                <td class="grey lighten-2 text-start text-end font-weight-bold">{{ numberFormat(totalSales.fresh.value,'0,0') }}</td>
                                <td class="grey lighten-2 text-start text-end font-weight-bold">{{ numberFormat(totalSales.fresh.margin,'0,0') }}</td>
                                <td class="grey lighten-2 text-start text-end font-weight-bold">{{ numberFormat(totalSales.fresh.marginPct,'0.0') }}</td>
                                <td class="grey lighten-2 text-start text-end font-weight-bold">{{ numberFormat(totalSales.old.volume,'0,0.00') }}</td>
                                <td class="grey lighten-2 text-start text-end font-weight-bold">{{ numberFormat(totalSales.old.value,'0,0') }}</td>
                                <td class="grey lighten-2 text-start text-end font-weight-bold">{{ numberFormat(totalSales.old.margin,'0,0') }}</td>
                                <td class="grey lighten-2 text-start text-end font-weight-bold">{{ numberFormat(totalSales.old.marginPct,'0.0') }}</td>
                                <td class="grey lighten-2 text-start text-end font-weight-bold">{{ numberFormat(totalSales.combined.value,'0,0') }}</td>
                                <td class="grey lighten-2 text-start text-end font-weight-bold">{{ numberFormat(totalSales.combined.margin,'0,0') }}</td>
                                <td class="grey lighten-2 text-start text-end font-weight-bold">{{ numberFormat(totalSales.combined.marginPct,'0.0') }}</td>
                            </tr>
                        </tfoot>
                    </template>
                </v-simple-table>
                <v-simple-table dense class="appic-table-light mt-3" :key="stocksTableKey">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th @click="openStocksDrillDown()" colspan="12" class="text-center light-green lighten-1" style="cursor: pointer; font-size: 1.0em !important;">
                                    {{ filterValues.warehouse + ' ' + $t('message.stockAtPurchaseValue') + ' WK ' + filterValues.week + ' (' + salesWeekRange + ')' }}
                                </th>
                            </tr>
                            <tr>
                                <th rowspan="2" class="text-start light-green lighten-2 pa-1" style="width: 100px !important; vertical-align: bottom !important; padding-bottom: 7px !important;">{{ $t('message.product') }}</th>
                                <th rowspan="2" class="text-end light-green lighten-3 pa-1" style="width: 100px !important; vertical-align: bottom !important; padding-bottom: 7px !important;">{{ $t('message.opening') + ' USD' }}</th>
                                <th rowspan="2" class="text-end light-green lighten-3 pa-1" style="width: 100px !important; vertical-align: bottom !important; padding-bottom: 7px !important;">{{ $t('message.received') + ' USD' }}</th>
                                <th colspan="2" class="text-end light-green lighten-4 pa-1" style="text-align: center !important;">{{ $t('message.sold') + ' USD' }}</th>
                                <th rowspan="2" class="text-end light-green lighten-3 pa-1" style="width: 75px !important; vertical-align: bottom !important; padding-bottom: 7px !important;">{{ $t('message.closing') + ' USD' }}</th>
                                <th rowspan="2" class="text-end light-green lighten-4 pa-1" style="width: 50px !important; vertical-align: bottom !important; padding-bottom: 7px !important;">%</th>
                                <th colspan="2" class="text-end light-green lighten-4 pa-1" style="text-align: center !important;">{{ $t('message.oldStock') + ' USD' }}</th>
                                <th colspan="2" class="text-end light-blue lighten-3 pa-1" style="text-align: center !important;">{{ $t('message.incoming') + ' USD' }}</th>
                            </tr>
                            <tr>
                                <th class="text-end light-green lighten-4 pa-1" style="width: 75px !important;">{{ $t('message.fresh') }}</th>
                                <th class="text-end light-green lighten-4 pa-1" style="width: 75px !important;">{{ $t('message.old') }}</th>
                                <th class="text-end light-green lighten-4 pa-1" style="width: 75px !important;">{{ $t('message.value') }}</th>
                                <th class="text-end light-green lighten-4 pa-1" style="width: 100px !important;">{{ $t('message.pctTotalValue') }}</th>
                                <th class="text-end light-blue lighten-3 pa-1" style="width: 50px !important;">{{ $t('message.value') }}</th>
                                <th class="text-start light-blue lighten-3 pa-1">{{ $t('message.eta') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in stocks">
                                <td class="text-start font-weight-bold">{{ item.Stock.product }}</td>
                                <td class="text-start text-end">
                                    <span v-if="item.Stock.openingValue > 0">{{ numberFormat(item.Stock.openingValue,'0,0') }}</span>
                                    <span v-else>{{ numberFormat(0,'0,0') }}</span>
                                </td>
                                <td class="text-start text-end">
                                    <span v-if="item.Stock.receivedValue > 0">{{ numberFormat(item.Stock.receivedValue,'0,0') }}</span>
                                    <span v-else>{{ numberFormat(0,'0,0') }}</span>
                                </td>
                                <td class="text-start text-end">
                                    <span v-if="item.Stock.soldFreshValue > 0">{{ numberFormat(item.Stock.soldFreshValue,'0,0') }}</span>
                                    <span v-else>{{ numberFormat(0,'0,0') }}</span>
                                </td>
                                <td class="text-start text-end">
                                    <span v-if="item.Stock.soldOldValue > 0">{{ numberFormat(item.Stock.soldOldValue,'0,0') }}</span>
                                    <span v-else>{{ numberFormat(0,'0,0') }}</span>
                                </td>
                                <td class="text-start text-end">
                                    <span v-if="item.Stock.closingValue > 0">{{ numberFormat(item.Stock.closingValue,'0,0') }}</span>
                                    <span v-else>{{ numberFormat(0,'0,0') }}</span>
                                </td>
                                <td class="text-start text-end">
                                    <span v-if="item.Stock.closingValuePct > 0">{{ numberFormat(item.Stock.closingValuePct,'0,0.0') }}</span>
                                    <span v-else>{{ numberFormat(0,'0,0') }}</span>
                                </td>
                                <td class="text-start text-end">
                                    <span v-if="item.Stock.oldValue > 0">{{ numberFormat(item.Stock.oldValue,'0,0') }}</span>
                                    <span v-else>{{ numberFormat(0,'0,0') }}</span>
                                </td>
                                <td class="text-start text-end">
                                    <span v-if="item.Stock.oldPctOfWareHouse > 0">{{ numberFormat(item.Stock.oldPctOfWareHouse,'0,0.0') }}</span>
                                    <span v-else>{{ numberFormat(0,'0,0.0') }}</span>
                                </td>
                                <td class="text-start text-end">
                                    <span v-if="item.Stock.incomingValue > 0">{{ numberFormat(item.Stock.incomingValue,'0,0') }}</span>
                                    <span v-else>{{ numberFormat(0,'0,0') }}</span>
                                </td>
                                <td class="text-start">
                                    <template v-for="(etaDate, index) in item.Stock.incomingEta">
                                        <span>{{ 'WK' + etaDate + (index >= 0 && index < item.Stock.incomingEta.length - 1 ? ', ' : '') }}</span>
                                        <br v-if="index + 1 > 1 && (index + 1) % 5 === 0">
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td class="grey lighten-2 text-start font-weight-bold pa-1">{{ $t('message.totals') }}</td>
                                <td class="grey lighten-2 text-start text-end font-weight-bold">{{ numberFormat(totalStocks.openingValue,'0,0') }}</td>
                                <td class="grey lighten-2 text-start text-end font-weight-bold">{{ numberFormat(totalStocks.receivedValue,'0,0') }}</td>
                                <td class="grey lighten-2 text-start text-end font-weight-bold">{{ numberFormat(totalStocks.sold.freshValue,'0,0') }}</td>
                                <td class="grey lighten-2 text-start text-end font-weight-bold">{{ numberFormat(totalStocks.sold.oldValue,'0,0') }}</td>
                                <td class="grey lighten-2 text-start text-end font-weight-bold">{{ numberFormat(totalStocks.closingValue,'0,0') }}</td>
                                <td class="grey lighten-2 text-start text-end font-weight-bold">{{ numberFormat(100,'0,0.0') }}</td>
                                <td class="grey lighten-2 text-start text-end font-weight-bold">{{ numberFormat(totalStocks.old.value,'0,0') }}</td>
                                <td class="grey lighten-2 text-start text-end font-weight-bold">{{ numberFormat(100,'0,0.0') }}</td>
                                <td class="grey lighten-2 text-start text-end font-weight-bold">{{ numberFormat(totalStocks.incomingValue,'0,0s') }}</td>
                                <td class="grey lighten-2 text-start text-end font-weight-bold"></td>
                            </tr>
                        </tfoot>
                    </template>
                </v-simple-table>
            </div>
            <WeeklySalesDetail
                :detail-title="filterValues.warehouse + ' ' + $t('message.sales') + ' WK ' + filterValues.week + ' (' + salesWeekRange + ')'"
                :dialog.sync="salesDrillDownDialog"
                :filters="filters"
                @dialog-closed="salesDrillDownDialogClosed()"
            />
            <WeeklyStocksDetail
                :detail-title="filterValues.warehouse + ' ' + $t('message.stockAtPurchaseValue') + ' WK ' + filterValues.week + ' (' + salesWeekRange + ')'"
                :dialog.sync="stocksDrillDownDialog"
                :filters="filters"
                @dialog-closed="stocksDrillDownDialogClosed()"
            />
        </div>
    </v-fade-transition>
</template>

<script>
import {mapGetters} from "vuex";
import {log, numberFormat, formatDate, getCurrentWeek, getDateRangeOfWeek} from "Helpers/helpers";
import {api} from "Api";

const ExportTableJson = () => import("Components/Appic/ExportTableJson");
const WeeklySalesDetail= () => import("Components/Appic/StockReports/WeeklySalesDetail.vue");
const WeeklyStocksDetail= () => import("Components/Appic/StockReports/WeeklyStocksDetail.vue");

export default {
    name: "WeeklySalesListV2",
    components: {WeeklyStocksDetail, WeeklySalesDetail, ExportTableJson},
    data() {
        return {
            filterValues: {
                warehouse: 'MY',
                week: 1
            },
            incomingWeekRange: '',
            loading: {
                weeklySales: false
            },
            sales: [],
            salesDrillDownDialog: false,
            salesTableKey: 1,
            salesWeekRange: '',
            stocksTableKey: 9,
            sortedCalendarWeeks: [],
            stocks: [],
            stocksDrillDownDialog: false,
            totalSales: {
                combined: {
                    volume: 0,
                    value: 0,
                    margin: 0,
                    marginPct: 0
                },
                fresh: {
                    volume: 0,
                    value: 0,
                    margin: 0,
                    marginPct: 0
                },
                old: {
                    volume: 0,
                    value: 0,
                    margin: 0,
                    marginPct: 0
                }
            },
            totalStocks: {
                incomingValue: 0,
                openingValue: 0,
                receivedValue: 0,
                sold: {
                    freshValue: 0,
                    oldValue: 0
                },
                closingValue: 0,
                old: {
                    value: 0,
                    pctOfWareHouse: 0
                }
            }
        }
    },
    computed: {
        ...mapGetters([
            'offices',
            'calendarWeeks'
        ]),
        filters() {
            return [
                {
                    field: 'localsales.warehouse',
                    value: this.filterValues.warehouse
                },
                {
                    field: 'localsales.weekNumber',
                    value: this.filterValues.week
                }
            ]
        },
        filteredOffices () {
            return this.offices.filter( o => [1,21].includes(o.Office.id))
        },
    },
    methods: {
        calculateTotals() {
            return new Promise((resolve, reject) => {
                try {
                    //totals
                    this.totalSales.fresh.volume = 0
                    this.totalSales.fresh.value = 0
                    this.totalSales.fresh.margin = 0
                    this.totalSales.old.volume = 0
                    this.totalSales.old.value = 0
                    this.totalSales.old.margin = 0
                    this.totalSales.combined.volume = 0
                    this.totalSales.combined.value = 0
                    this.totalSales.combined.margin = 0
                    this.sales.forEach(sale => {
                        this.totalSales.fresh.volume += sale.Stock.freshVolume
                        this.totalSales.fresh.value += sale.Stock.freshValue
                        if(sale.Stock.freshMargin > 0) this.totalSales.fresh.margin += sale.Stock.freshMargin
                        this.totalSales.old.volume += sale.Stock.oldVolume
                        this.totalSales.old.value += sale.Stock.oldValue
                        if(sale.Stock.oldMargin > 0) this.totalSales.old.margin += sale.Stock.oldMargin
                        this.totalSales.combined.volume += sale.Stock.totalVolume
                        this.totalSales.combined.value += sale.Stock.totalValue
                        this.totalSales.combined.margin += sale.Stock.totalMargin
                    })
                    this.totalSales.combined.marginPct = ((this.totalSales.combined.margin / this.totalSales.combined.value) * 100).toFixed(1)
                    this.totalSales.old.marginPct = ((this.totalSales.old.margin / this.totalSales.old.value) * 100).toFixed(1)
                    this.totalSales.fresh.marginPct = ((this.totalSales.fresh.margin / this.totalSales.fresh.value) * 100).toFixed(1)

                    //stocks
                    this.totalStocks.openingValue = 0
                    this.totalStocks.receivedValue = 0
                    this.totalStocks.sold.freshValue = 0
                    this.totalStocks.sold.oldValue = 0
                    this.totalStocks.closingValue = 0
                    this.totalStocks.old.value = 0
                    this.totalStocks.old.pctOfWareHouse = 0
                    this.totalStocks.incomingValue = 0
                    this.stocks.forEach(stock => {
                        this.totalStocks.openingValue += stock.Stock.openingValue
                        this.totalStocks.receivedValue += stock.Stock.receivedValue
                        this.totalStocks.sold.freshValue += stock.Stock.soldFreshValue
                        this.totalStocks.sold.oldValue += stock.Stock.soldOldValue
                        this.totalStocks.closingValue += stock.Stock.closingValue
                        this.totalStocks.old.value += stock.Stock.oldValue
                        this.totalStocks.incomingValue += stock.Stock.incomingValue
                    })
                    this.totalStocks.old.pctOfWareHouse = (( this.totalStocks.old.value / this.totalStocks.closingValue) * 100).toFixed(5)
                    resolve('done')
                } catch(e) {
                    reject(e)
                }
            })
        },
        fetchSales() {
            return new Promise((resolve, reject) => {
                const conditions = [
                    {
                        field: 'localsales.warehouse',
                        value: this.filterValues.warehouse
                    },
                    {
                        field: 'localsales.weekNumber',
                        value: this.filterValues.week
                    }
                ]
                api
                    .get("/reports/stocks/weekly-sales",{
                        params: {
                            conditions: conditions
                        }
                    })
                    .then(response => {
                        this.sales = response.data.data
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        fetchStocks() {
            return new Promise((resolve, reject) => {
                const conditions = [
                    {
                        field: 'localsales.warehouse',
                        value: this.filterValues.warehouse
                    },
                    {
                        field: 'localsales.weekNumber',
                        value: this.filterValues.week
                    }
                ]
                api
                    .get("/reports/stocks/weekly-stock-movement",{
                        params: {
                            conditions: conditions
                        }
                    })
                    .then(response => {
                        this.stocks = response.data.data
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        fetchReport() {
            this.loading.weeklySales = true
            this.salesTableKey = this.salesTableKey + Math.floor(Math.random() * 100)
            this.stocksTableKey = this.stocksTableKey + Math.floor(Math.random() * 100)
            this.salesWeekRange = this.getDateRangeOfWeek(this.filterValues.week)
            this.fetchSales()
                .then(() => {
                    this.fetchStocks()
                        .then(() => {
                            this.loading.weeklySales = false
                            this.calculateTotals()
                                .then(() => {
                                    this.loading.weeklySales = false
                                })
                                .catch((err) => {
                                    log(err)
                                    this.loading.weeklySales = false
                                })
                        })
                        .catch((err) => {
                            log(err)
                            this.loading.weeklySales = false
                        })
                })
                .catch(() => {
                    this.loading.weeklySales = false
                })
            return true
        },
        formatDate,
        getCurrentWeek,
        getDateRangeOfWeek,
        getPreviousWeek() {
            return new Promise((resolve, reject) => {
                try {
                    const currentDate = new Date();
                    let weekNumber = currentDate.getWeek()
                    weekNumber = weekNumber - 1;
                    resolve(weekNumber)
                } catch(e){
                    reject(e)
                }
            })
        },
        numberFormat,
        openSalesDrillDown() {
            this.salesDrillDownDialog = true
        },
        openStocksDrillDown() {
            this.stocksDrillDownDialog = true
        },
        salesDrillDownDialogClosed() {
            this.salesDrillDownDialog = false
        },
        stocksDrillDownDialogClosed() {
            this.stocksDrillDownDialog = false
        }
    },
    created() {
        this.sortedCalendarWeeks = this.calendarWeeks
    },
    mounted() {
        this.sortedCalendarWeeks.sort((a,b) => {
            return b.Calendarweek.id - a.Calendarweek.id
        })
        this.filterValues.week = this.getCurrentWeek()
        this.salesWeekRange = this.getDateRangeOfWeek(this.filterValues.week)
        this.fetchReport()
    }
}
</script>

<style scoped>
.filter-width-100 {
    width: 100px !important;
    max-width: 100px !important;
}
.bg-lightblue {
    background-color: lightblue;
}
.v-data-table::v-deep .text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.v-data-table::v-deep .text-end {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
</style>